import './App.css';
import Banner from './components/Banner';
import Contact from './components/Contact';
import Footer from './components/Footer';
import Header from "./components/Header"
import Info from './components/Info';
import SingleInfo from './components/SingleInfo';
import AboutUs from './components/AboutUs';

function App() {
    return (
        <>
            <Header />
            <Banner />
            <SingleInfo />
            <Info />
            <AboutUs />
            {/* <Contact /> */}
            <Footer />
        </>
    );
}

export default App;
