import React, { useState } from 'react'
import axios from 'axios'
import Swal from 'sweetalert2';

const Banner = () => {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const { firstName, lastName, email, phone } = formData;
        const data = {
            firstName,
            lastName,
            email,
            phoneNumber: phone
        };
        // https://FinansSinyal.com
        try {
            await axios.post(`${window.location.origin}/api/Form/SubmitForm`, data, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            Swal.fire({
                title: "Başarılı",
                text: "Mesajınız başarıyla iletilmiştir",
                icon: "success"
            }).then((result) => {
                if (result.isConfirmed) {
                    window.location.reload();
                }
            })
        } catch (error) {
            console.error('Form submission error:', error);
            Swal.fire({
                title: "Bir Hata Oluştu",
                text: "Bir hata oluştu. Lütfen tekrar deneyiniz.",
                icon: "error"
            });
        }
    };

    return (
        <div className='banner-content d-flex align-items-center justify-content-center' id='banner'>
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-7">
                        <div className="d-flex align-items-center flex-column w-75">
                            <h3 className='info-card-title' style={{ fontSize: "64px" }}>Karlı Yatırımlar İçin Doğru <span style={{ color: "#f7ab54" }}>Sinyaller</span></h3>
                            <p className='info-card-paragraph' style={{ fontSize: "20px" }}>Profesyonel analizler ve anlık sinyallerle yatırımlarınızı optimize edin. Finansal başarı için güvenilir sinyallerimizi kullanarak piyasada öne çıkın.</p>
                        </div>
                    </div>
                    <div className="col-lg-5 mobile-banner-contact">
                        <div className="banner-card p-4">
                            <h3 className='mb-4'>Bizimle İletişime Geçin</h3>
                            <form onSubmit={handleSubmit}>
                                <div className='d-flex flex-column'>
                                    <label htmlFor="firstName" className='form-label'>İsim</label>
                                    <input
                                        type="text"
                                        name="firstName"
                                        placeholder='İsminizi Girin'
                                        className='form-control'
                                        value={formData.firstName}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div className='d-flex flex-column mt-3'>
                                    <label htmlFor="lastName" className='form-label'>Soyisim</label>
                                    <input
                                        type="text"
                                        name="lastName"
                                        placeholder='Soyisminizi Girin'
                                        className='form-control'
                                        value={formData.lastName}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div className='d-flex flex-column mt-3'>
                                    <label htmlFor="email" className='form-label'>E-posta Adresi</label>
                                    <input
                                        type="email"
                                        name="email"
                                        placeholder='E-posta Adresinizi Girin'
                                        className='form-control'
                                        value={formData.email}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div className='d-flex flex-column mt-3'>
                                    <label htmlFor="phone" className='form-label'>Telefon Numarası</label>
                                    <input
                                        type="tel"
                                        name="phone"
                                        placeholder='Telefon Numaranızı Girin'
                                        className='form-control'
                                        value={formData.phone}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div className='d-flex flex-column mt-3'>
                                    <label htmlFor="reference" className='form-label'>Referans Kodu</label>
                                    <input
                                        type="text"
                                        name="reference"
                                        placeholder='Referans Kodunuzu Girin'
                                        className='form-control'
                                    />
                                </div>
                                <div className='mt-4'>
                                    <button className='btn btn-primary-v2 py-2 w-100' type="submit">
                                        Gönder
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Banner