import React from 'react'
import AboutUsImg from "../asset/img/about-us.webp"

const AboutUs = () => {
    return (
        <div className='about-us-content py-5' id='about-us'>
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <h3 className='info-card-title'>Hakkımızda</h3>
                        <p className='info-card-paragraph mb-0'>Biz, yatırımcılara en doğru ve güvenilir finansal sinyalleri sağlamak için çalışan bir ekibiz. Amacımız, kullanıcılarımızın yatırımlarını en iyi şekilde değerlendirmelerine yardımcı olmak. Uzman ekibimiz ve gelişmiş analiz yöntemlerimizle finansal başarıya ulaşmanız için yanınızdayız. Yatırım yolculuğunuzda bize güvenin ve kazançlı çıkın!</p>
                    </div>
                    <div className="col-lg-6 text-end">
                        <img src={AboutUsImg} alt="about-us" width="100%" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutUs