import React from 'react'
import Management from "../asset/img/info.jpg"
import Investing from "../asset/img/info-2.png"
import Debt from "../asset/img/info-3.jpg"
import Retired from "../asset/img/info-4.jpeg"

const Info = () => {
    return (
        <div className='info-content' id='info'>
            <div className="container">
                <h3 className='single-info-title' style={{ fontSize: "54px" }}>Her Sinyalde Kazanca Giden Yol!</h3>
                <div className="row align-items-center justify-content-center" style={{ marginTop: "75px" }}>
                    <div className="col-lg-5">
                        <h3 className='info-card-title'>Hisse Senedi Sinyalleri</h3>
                        <p className='info-card-paragraph'>Günlük ve haftalık hisse senedi sinyalleri ile piyasayı takip edin. Uzmanlarımızın analizleri sayesinde karlı alım-satım fırsatlarını yakalayın.</p>
                    </div>
                    <div className="col-lg-6 text-end">
                        <img src={Management} alt="info-image" width="100%" className='info-image' />
                    </div>
                </div>
                <div className="row align-items-center justify-content-center info-card-content">
                    <div className="col-lg-6">
                        <img src={Investing} alt="info-image" width="100%" className='info-image' />
                    </div>
                    <div className="col-lg-5 text-end">
                        <h3 className='info-card-title'>Kripto Para Sinyalleri</h3>
                        <p className='info-card-paragraph'>Kripto para piyasasında doğru zamanda doğru adımları atın. Bitcoin, Ethereum ve diğer altcoin'ler için güncel sinyallerle yatırım yapın.</p>
                    </div>
                </div>
                <div className="row align-items-center justify-content-center info-card-content">
                    <div className="col-lg-5">
                        <h3 className='info-card-title'>Komodite Sinyalleri</h3>
                        <p className='info-card-paragraph'>Altın, gümüş, petrol ve diğer emtialar için güvenilir sinyaller alın. Emtia piyasalarındaki fırsatları değerlendirerek portföyünüzü çeşitlendirin.</p>
                    </div>
                    <div className="col-lg-6">
                        <img src={Debt} alt="info-image" width="100%" className='info-image' />
                    </div>
                </div>
                <div className="row align-items-center justify-content-center info-card-content">
                    <div className="col-lg-6">
                        <img src={Retired} alt="info-image" width="100%" className='info-image' />
                    </div>
                    <div className="col-lg-5 text-end">
                        <h3 className='info-card-title'>Opsiyon Sinyalleri</h3>
                        <p className='info-card-paragraph'>Opsiyon ticaretinde doğru stratejilerle kazanç sağlayın. Piyasa analizleri ve uzman sinyallerimizle riskleri minimize edin, fırsatları değerlendirin.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Info