import React from 'react'
import WhyUs from '../asset/img/why-us.jpg'

const SingleInfo = () => {
    return (
        <div className='single-info-content' id='single-info'>
            <div className="container">
                <h3 className='single-info-title' style={{fontSize: "54px"}}>Doğru Sinyaller, Güvenilir Kazançlar!</h3>
                <div className="row align-items-center justify-content-center" style={{ marginTop: "75px" }}>
                    <div className="col-lg-6">
                        <img src={WhyUs} alt="single-info-image" width="90%" className='info-image' />
                    </div>
                    <div className="col-lg-5">
                        <h3 className='info-card-title'>Neden Bizi Tercih Etmelisiniz?</h3>
                        <p className='info-card-paragraph'>Uzman analizlerimiz ve yüksek doğruluk oranına sahip sinyallerimiz ile yatırımlarınızı güvenle yönetin. Gerçek zamanlı bildirimler ve kullanıcı dostu platformumuz sayesinde finansal hedeflerinize ulaşmak artık daha kolay. Güvenilir ve etkili sinyallerimizle piyasanın bir adım önünde olun.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SingleInfo